import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const vuetify = new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				purple: '#524b8e',
				primary: '#524b8e',
				lightblue: '#b1d5e5',
				lightgrey: '#d4d2e3',
				yellow: '#e8c078',
				red: '#ca6064',
				orange: '#d99486',
				pink: '#f8f3f0',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				link: colors.blue.darken4,
			},
		},
	},
})

export default { vuetify }
