<script>
import star from './assets/star.png'
export default {
	data() {
		return {
			star,
		}
	},
	computed: {
		config() {
			return this.$srv('Home_B_ProductsCards', {})
		},
		showSection() {
			return this.config?.products?.length
		},
	},
	mounted() {
		if (this.config?.products?.length) {
			this.$eventer.trigger('product-list:view', {
				products: this.config.products,
				listId: 'home-b-products-cards',
			})
		}
	},
}
</script>

<template>
	<Container class="pa-0 mb-8 mt-16" v-if="showSection">
		<v-row>
			<v-col
				cols="12"
				v-if="config.title"
				class="text-center font-4 font-md-6 primary--text font-weight-bold mb-5 d-flex justify-center font-alt"
			>
				<div class="title-container font-alt">
					{{ config.title }}
					<Media :src="star" alt="" width="2.1rem" class="mt-2" />
				</div>
			</v-col>

			<v-col
				cols="12"
				sm="4"
				md="3"
				v-for="(product, i) in config.products"
				:key="product.id"
				class="mb-4 px-12 px-sm-3"
			>
				<ProductCard :product="product" list-id="home-b-products-cards" :list-index="i" />
			</v-col>
			<v-col cols="12" v-if="config.ctaTxt && config.link" class="d-flex justify-center align-center">
				<SafeLink :to="config.link">
					<Button
						class="white--text font-weight-bold rounded-xl"
						color="red"
						width="250px"
						height="80px"
					>
						{{ config.ctaTxt }}
					</Button>
				</SafeLink>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped>
.title-container {
	width: 100%;
	max-width: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>
