<script>
import { get, sync } from 'vuex-pathify'
import { createPortals } from '@/__shared/utils/lib_utils'
import makeSummary from '@/../plugit/shop-order/helpers/make-summary'

const CartItemPortals = createPortals(require.context('@/site', true, /portal_CartItem(-.+)?\.vue/))

export default {
	components: { CartItemPortals },
	lang: 'shop',
	data() {
		return {
			cartKey: 0,
		}
	},
	computed: {
		summary() {
			return makeSummary(this.order)
		},
		cartDrawer: sync('shop/cartDrawer'),
		order: get('cart/order'),
		joinedItems() {
			return this.summary.getJoinedItems()
		},
		items() {
			return this.order?.items
		},
		isCartEmpty() {
			return !this.order?.items?.length
		},
	},
	methods: {
		goToCheckout() {
			this.cartDrawer = false
			this.$router.push({ name: 'checkout' })
		},
		keepBuying() {
			this.cartDrawer = false
			this.$shop.goShop()
		},
	},
	watch: {
		items() {
			this.cartKey += 1
		},
	},
}
</script>

<template>
	<v-navigation-drawer
		v-model="cartDrawer"
		class="pa-4 drawer-index"
		right
		fixed
		width="700"
		max-width="100%"
		overlay-color="#000"
		temporary
		:overlay-opacity="0.8"
		touchless
	>
		<CartItemPortals :joined-items="joinedItems" v-if="order" />
		<v-list-item class="px-2">
			<div class="font-weight-bold font-4 darkblue--text">
				{{ 'Carrito' | lang }}
			</div>
			<v-spacer></v-spacer>
			<Button color="link" text @click="cartDrawer = false">
				<v-icon>mdi-close</v-icon> {{ 'Cerrar' | lang }}
			</Button>
		</v-list-item>
		<v-divider />
		<v-list-item v-if="isCartEmpty" class="pt-4 d-flex justify-center">
			<div class="text-center font-weight-bold">
				{{ 'No hay productos en el Carrito' | lang }}
			</div>
		</v-list-item>
		<template v-else>
			<div class="px-sm-6" v-for="(jItem, i) of joinedItems" :key="`${cartKey}-${i}`">
				<CartItem :joined-item="jItem" :order="order" class="py-6" :index="i" />
				<v-divider v-if="i < joinedItems.length - 1" />
			</div>
			<div class="px-sm-6 pb-4">
				<OrderSummary-Totals :order="order" :summary="summary" />
			</div>
		</template>
		<v-list-item class="justify-center mb-4">
			<Button color="success" :disabled="isCartEmpty" x-large @click="goToCheckout">
				{{ 'Finalizar compra' | lang }}
			</Button>
		</v-list-item>
		<v-list-item class="justify-center">
			<Button class="mt-2" text color="link" @click="keepBuying">
				{{ 'Seguir comprando' | lang }}
			</Button>
		</v-list-item>
	</v-navigation-drawer>
</template>
