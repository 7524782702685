<script>
function youtube_parser(url) {
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
	var match = url.match(regExp)
	return match && match[7].length == 11 ? match[7] : false
}

export default {
	computed: {
		url() {
			let link = this.$srv('Home_G_Video.link')
			if (!link) return null
			let code = youtube_parser(link)
			if (!code) {
				if (link.startsWith('http')) return null
				else code = link
			}
			return `https://www.youtube.com/embed/${code}`
		},
		isMobile() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 200
				case 'sm':
					return 400
				default:
					return 600
			}
		},
	},
}
</script>

<template>
	<Container :fluid="{ xs: true, md: false }" v-if="url">
		<iframe
			:src="url"
			width="100%"
			:height="isMobile"
			title="YouTube video player"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen
		></iframe>
	</Container>
</template>

<style scoped></style>
