<script>
export default {
	lang: 'home',
	data() {
		return {
			carouselItems: this.$srv('Home_H_Slider'),
		}
	},
}
</script>

<template>
	<div class="yellow py-10" v-if="carouselItems.length">
		<div class="text-center primary--text font-6 font-weight-bold font-alt px-5 pb-5">
			{{ '¿Qué dicen de nosotros?' | lang }}
		</div>
		<Container :fluid="{ xs: true, md: false }">
			<Carousel
				class="carousel"
				:items="carouselItems"
				:options="{
					panelsPerView: { xs: 1.5, sm: 2.5, md: 4 },
					itemsPadding: 3,
					arrows: { md: true },
					arrowsInset: false,
					arrowsMargin: 5,
					itemWrapperClass: 'py-1',
				}"
				:flicking-options="{
					align: 'prev',
					bound: true,
				}"
			>
				<template #panel="{ item }">
					<Media :src="item.img" img-class="rounded-lg" aspect-ratio="1.017"></Media>
				</template>
			</Carousel>
		</Container>
	</div>
</template>

<style scoped>
.carousel >>> .arrow {
	width: 55px;
	height: 55px;
}
.carousel >>> .arrow-icon {
	color: var(--v-purple-base) !important;
	font-size: 80px !important;
}
</style>
