<script>
export default {
	name: 'QtyInput',
	props: {
		qtyModel: { type: Number, default: 1 },
		disabled: Boolean,
		maxQty: Number,
		loading: Boolean,
		unitMetric: String,
		packMetric: String,
		step: {
			type: Number,
			default: 1,
		},
	},
	model: {
		prop: 'qtyModel',
		event: 'updateQtyModel',
	},
	data() {
		return {
			qty: null,
			qtyInput: null,
		}
	},
	computed: {
		unitMetricWord() {
			if (!this.unitMetric) return ''
			return this.qty == 1 ? this.unitMetric.split('/')[0] : this.unitMetric.split('/').join('')
		},
		packMetricWord() {
			if (!this.packMetric) return ''
			return this.packQty == 1 ? this.packMetric.split('/')[0] : this.packMetric.split('/').join('')
		},
		cMaxQty() {
			return this.maxQty ? this.roundToStep(this.maxQty, false) : null
		},
		cStep() {
			return this.step || 1
		},
		packQty() {
			return Math.round(this.qty / this.cStep)
		},
	},
	watch: {
		qty(value) {
			this.$emit('updateQtyModel', value)
		},
		qtyModel(value) {
			this.setQty(value)
		},
	},
	methods: {
		roundToStep(n, up = false) {
			let div = Number((n / this.cStep).toFixed(2))
			let result = up ? Math.ceil(div) : Math.floor(div)
			return Number((this.cStep * result).toFixed(2))
		},
		addQty() {
			this.setQty(this.qty + this.cStep)
		},
		subtractQty() {
			this.setQty(this.qty - this.cStep)
		},
		setQty(n) {
			if (isNaN(n)) {
				n = this.qty
			}
			let newQty = this.limitQty(n)
			newQty = this.roundToStep(newQty, true)
			this.qty = newQty
			this.qtyInput = `${newQty} ${this.unitMetricWord}`
		},
		limitQty(n) {
			if (this.cMaxQty) {
				n = Math.min(this.cMaxQty, n)
			}
			return Math.max(this.cStep, n)
		},
		onFocus() {
			this.qtyInput = this.qty
		},
		onBlur() {
			this.setQty()
		},
	},
	created() {
		this.setQty(this.qtyModel)
	},
}
</script>

<template>
	<div class="d-flex align-center">
		<Button
			@click="subtractQty"
			:ripple="false"
			color="yellow"
			class="mr-2"
			fab
			x-small
			depressed
			:disabled="loading || disabled"
		>
			<v-icon color="white">mdi-minus</v-icon>
		</Button>
		<div
			class="container-text-field flex-grow-1"
			:class="{ 'container-text-field--pack-metric': !!packMetric }"
		>
			<v-text-field
				type="tel"
				v-model="qtyInput"
				@change="setQty"
				@focus="onFocus"
				@blur="onBlur"
				hide-details
				small
				class="pt-0 mt-0 mx-2 rounded-md white"
				:loading="loading"
				:disabled="disabled"
				height="40px"
				outlined
				flat
			/>
			<span v-if="packMetric" class="pack-metric-text grey--text">{{ packQty }} {{ packMetricWord }}</span>
		</div>
		<Button
			@click="addQty"
			:ripple="false"
			color="yellow"
			class="ml-2"
			fab
			x-small
			depressed
			:disabled="loading || disabled"
		>
			<v-icon color="white">mdi-plus</v-icon>
		</Button>
	</div>
</template>

<style lang="scss" scoped>
/*.v-text-field {
	max-width: 106px !important;
	width: 106px !important;
}*/
.v-text-field ::v-deep input {
	text-align: center;
	font-size: 1rem;
}
.container-text-field {
	position: relative;
}
.container-text-field--pack-metric ::v-deep .v-text-field__slot {
	position: relative;
	top: -5px;
}
.pack-metric-text {
	position: absolute;
	bottom: 0;
	font-size: 12px;
	left: 50%;
	transform: translateX(-50%);
	pointer-events: none;
}
.v-text-field {
	width: 130px !important;
}
.v-text-field ::v-deep input {
	text-align: center;
}
.v-text-field ::v-deep .v-input__slot {
	min-height: 48px;
}
</style>
