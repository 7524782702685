<script>
export default {
	props: {
		customClass: {
			type: String,
			default: 'white--text red rounded-xl px-2 py-1 font-0 nowrap',
		},
		amount: Number,
	},
}
</script>

<template>
	<DiscountText :amount="amount" :class="customClass" text="Off" />
</template>
