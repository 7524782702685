<script>
export default {
	lang: 'home',
	name: 'FrequentlyAsked',
	data() {
		return {
			items: this.$srv('Home_L_ExpandibleTabs', []),
		}
	},
}
</script>

<template>
	<Container class="pt-16 mb-16" v-if="items">
		<v-row>
			<v-col cols="12">
				<div class="text-center font-6 font-weight-bold primary--text font-alt">
					{{ 'Preguntas Frecuentes' | lang }}
				</div>
			</v-col>
			<v-col cols="12">
				<v-expansion-panels focusable flat>
					<v-expansion-panel v-for="(item, i) in items" :key="i" class="pb-3">
						<v-expansion-panel-header class="purple--text font-2 font-weight-bold">
							{{ `${i + 1}. ${item.title}` }}
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div class="pt-4">
								{{ item.content }}
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</Container>
</template>

<style lang="scss" scoped>
.v-expansion-panel-header {
	border: 1px solid #524b8e;
	border-radius: 16px !important;
}

.v-expansion-panel.v-expansion-panel--active {
	.v-expansion-panel-header {
		color: #fff !important;
		background-color: #524b8e;
		& ::v-deep * {
			color: #fff !important;
		}
	}
}
</style>
