<script>
export default {
	data() {
		return {
			items: this.$srv('Home_A_Slider', []),
		}
	},

	computed: {
		ratio() {
			return this.$vuetify.breakpoint.xs ? 1 : 2.32
		},
	},
}
</script>

<template>
	<Container :fluid="{ xs: true, xl: false }">
		<Carousel
			v-if="items.length"
			class="main-carousel"
			:items="items"
			:options="{
				autoplay: 4000,
				arrows: { md: true },
				arrowsMargin: 10,
				arrowsInset: true,
			}"
			:flicking-options="{ circular: true, moveType: ['strict', { count: 1 }] }"
		>
			<template #panel="{ item }">
				<SafeLink :to="item.link">
					<Media :src="$vuetify.breakpoint.xs ? item.imgMobile : item.img" :aspect-ratio="ratio" cover />
				</SafeLink>
			</template>

			<template #free-slot="{ currentIndex, moveTo }">
				<div class="index-container">
					<div
						class="index"
						:class="{ active: currentIndex == i - 1 }"
						v-for="i of items.length"
						:key="i"
						@click="moveTo(i - 1)"
					></div>
				</div>
			</template>
		</Carousel>
	</Container>
</template>

<style scoped>
.index-container {
	position: absolute;
	top: 96%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 4;
	display: flex;
}

.index {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	transition: all 0.3s;
	cursor: pointer;
	background-color: #9083b8;
}

.index.active {
	transform: scale(1.6);
	background-color: #534c8e;
}

.main-carousel >>> .arrow {
	width: 55px;
	height: 55px;
}
.main-carousel >>> .arrow-icon {
	color: var(--v-purple-base) !important;
	font-size: 80px !important;
}
</style>
