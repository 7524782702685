var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{attrs:{"fluid":{ xs: true, xl: false }}},[(_vm.items.length)?_c('Carousel',{staticClass:"main-carousel",attrs:{"items":_vm.items,"options":{
			autoplay: 4000,
			arrows: { md: true },
			arrowsMargin: 10,
			arrowsInset: true,
		},"flicking-options":{ circular: true, moveType: ['strict', { count: 1 }] }},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
		var item = ref.item;
return [_c('SafeLink',{attrs:{"to":item.link}},[_c('Media',{attrs:{"src":_vm.$vuetify.breakpoint.xs ? item.imgMobile : item.img,"aspect-ratio":_vm.ratio,"cover":""}})],1)]}},{key:"free-slot",fn:function(ref){
		var currentIndex = ref.currentIndex;
		var moveTo = ref.moveTo;
return [_c('div',{staticClass:"index-container"},_vm._l((_vm.items.length),function(i){return _c('div',{key:i,staticClass:"index",class:{ active: currentIndex == i - 1 },on:{"click":function($event){return moveTo(i - 1)}}})}),0)]}}],null,false,3863787618)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }