var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.carouselItems.length)?_c('div',{staticClass:"yellow py-10"},[_c('div',{staticClass:"text-center primary--text font-6 font-weight-bold font-alt px-5 pb-5"},[_vm._v(" "+_vm._s(_vm._f("lang")('¿Qué dicen de nosotros?'))+" ")]),_c('Container',{attrs:{"fluid":{ xs: true, md: false }}},[_c('Carousel',{staticClass:"carousel",attrs:{"items":_vm.carouselItems,"options":{
				panelsPerView: { xs: 1.5, sm: 2.5, md: 4 },
				itemsPadding: 3,
				arrows: { md: true },
				arrowsInset: false,
				arrowsMargin: 5,
				itemWrapperClass: 'py-1',
			},"flicking-options":{
				align: 'prev',
				bound: true,
			}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
			var item = ref.item;
return [_c('Media',{attrs:{"src":item.img,"img-class":"rounded-lg","aspect-ratio":"1.017"}})]}}],null,false,807057155)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }