<script>
export default {
	lang: 'home',
	name: 'NewsletterSection',
}
</script>

<template>
	<div class="red py-10">
		<Container class="d-flex justify-center align-center flex-column px-0">
			<div class="font-4 pb-6 white--text font-weight-bold text-center max-width-text font-alt">
				{{ 'Enterate de todas las novedades y recibí promociones especiales' | lang }}
			</div>
			<NewsLetterForm class="w100" />
		</Container>
	</div>
</template>

<style scoped>
.max-width-text {
	max-width: 500px;
	width: 100%;
}
</style>
