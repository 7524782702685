<script>
export default {
	lang: 'home',
	name: 'NewsLetterForm',
	data() {
		return {
			email: '',
			isLoading: false,
			formMsg: null,
			isSuccess: false,
			validation: {},
		}
	},
	methods: {
		saveEmail() {
			if (this.isLoading) {
				return
			}
			this.$shopApi.post({
				url: '/newsletter-email',
				data: {
					email: this.email,
				},
				loading: (v) => (this.isLoading = v),
				onValidation: ({ validation }) => (this.validation = validation),
				onSuccess: () => {
					this.isSuccess = true
				},
			})
		},
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
	},
}
</script>

<template>
	<div>
		<div v-if="isSuccess" class="text-center">
			<div class="d-inline-block white--text yellow rounded-xl px-4 py-2 font-1 font-weight-bold">
				{{ 'Gracias por registrar tu correo en nuestro newsletter!' | lang }}
			</div>
		</div>
		<div class="d-flex justify-center flex-column flex-sm-row" v-else>
			<div :class="{ 'parent-form': !isMobile }">
				<form @submit.prevent>
					<Validator :validation="validation">
						<TextField
							v-if="!isSuccess"
							v-model="email"
							:label="$lang('Ingresá tu email')"
							@keydown.enter="saveEmail"
							outlined
							rounded
							solo
							flat
						>
						</TextField>
					</Validator>
				</form>
			</div>
			<Button
				rounded
				color="purple"
				class="white--text pl-12 pr-12 font-alt mt-6 mt-sm-0 py-7 py-sm-0"
				:class="{ 'newsletter-cta': !isMobile }"
				@click="saveEmail"
				:loading="isLoading"
			>
				{{ 'QUIERO SUSCRIBIRME' | lang }}
			</Button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.parent-form {
	max-width: 500px;
	width: 90%;
}

.newsletter-cta-parent {
	margin-left: -55px;
	height: 56px;
}

.newsletter-cta {
	height: 56px !important;
	margin-left: -56px;
}

::v-deep .v-text-field__details {
	position: absolute;
	top: 100%;
	* {
		color: white;
	}
}
</style>
