<script>
import { sync } from 'vuex-pathify'

export default {
	lang: 'login',
	data() {
		return {
			activeForm: 'login',
		}
	},
	computed: {
		closeBtnIcon() {
			return this.activeForm == 'login' ? 'mdi-close' : 'mdi-chevron-left'
		},
		closeBtnText() {
			return this.activeForm == 'login' ? this.$lang('Cerrar') : this.$lang('Atrás')
		},
		loginDrawer: sync('shop/loginDrawer'),
		drawerTitle() {
			switch (this.activeForm) {
				case 'signup':
					return this.$lang('REGÍSTRATE')
				case 'forgot':
					return this.$lang('RECUPERA TU CONTRASEÑA')
				case 'login':
				default:
					return this.$lang('INGRESA')
			}
		},
	},
	watch: {
		loginDrawer(value) {
			if (value) this.activeForm = 'login'
		},
	},
	methods: {
		closeDrawer() {
			if (this.activeForm == 'login') this.loginDrawer = false
			else this.activeForm = 'login'
		},
	},
}
</script>

<template>
	<v-navigation-drawer
		class="pa-4 drawer-index"
		v-model="loginDrawer"
		fixed
		width="600"
		max-width="100%"
		overlay-color="#000"
		temporary
		:right="$vuetify.breakpoint.xs"
		:overlay-opacity="0.8"
		touchless
	>
		<v-list-item class="px-2">
			<div class="font-weight-bold font-4 darkblue--text">
				{{ drawerTitle }}
			</div>
			<v-spacer></v-spacer>
			<Button text color="link" @click="closeDrawer">
				<v-icon>{{ closeBtnIcon }}</v-icon> {{ closeBtnText }}
			</Button>
		</v-list-item>
		<v-divider class="mb-4" />
		<div class="px-4">
			<LoginForms v-model="activeForm" />
		</div>
	</v-navigation-drawer>
</template>
