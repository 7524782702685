<script>
export default {
	props: {
		image: [String, Object],
		bgColor: String,
		textColor: String,
		ctaTxt: String,
		ctaLink: String,
		ctaColor: String,
		ctaTitle: String,
	},
	computed: {
		getCtaColor() {
			return !this.ctaColor ? 'primary' : this.ctaColor
		},
	},
}
</script>

<template>
	<v-card :to="ctaLink" height="100%" width="100%" flat rounded="xl" class="font-alt overflow-hidden">
		<Media :src="image" width="100%" style="position: relative">
			<v-card-text
				class="font-4 font-md-8 font-weight-bold text-center d-flex flex-column align-center justify-center"
				:class="[bgColor]"
				v-if="ctaTitle"
				style="min-height: 50% !important; position: absolute; bottom: 0"
			>
				<div v-if="ctaTitle" class="my-4 mt-md-0" :style="`color: ${textColor};`">{{ ctaTitle }}</div>
				<Button
					v-if="ctaTxt"
					rounded
					elevation="0"
					large
					class="white--text font-weight-bold px-13 py-8 text-capitalize"
					:color="getCtaColor"
				>
					{{ ctaTxt }}
				</Button>
			</v-card-text>
		</Media>
	</v-card>
</template>
