<script>
import offerBg from './assets/offer-bg.png'
export default {
	data() {
		return {}
	},
	computed: {
		offerBg() {
			return offerBg
		},
		ratio() {
			return this.$vuetify.breakpoint.name === 'xs' ? 0.65 : 2.32
		},
		config() {
			return this.$srv('Home_E_SliderCards', {})
		},
		items() {
			let { products, initCardTitle, initCardCtaText, initCardCtaLink } = this.config
			let items = [...products]
			if (initCardTitle) {
				items.unshift({
					customCard: true,
					title: initCardTitle,
					ctaTxt: initCardCtaText,
					ctaLink: initCardCtaLink,
				})
			}
			return items
		},
	},
	methods: {
		getProdListIdx(i) {
			return this.config?.initCardTitle ? i - 1 : i
		},
	},
	mounted() {
		let { products } = this.config
		if (products?.length) {
			this.$eventer.trigger('product-list:view', {
				products,
				listId: 'home-e-products-slider',
			})
		}
	},
}
</script>

<template>
	<Container :fluid="{ xs: true, sm: false }" class="py-15" v-if="items.length">
		<Carousel
			class="carousel"
			:items="items"
			:options="{
				panelsPerView: { xs: 1.5, sm: 3, md: 4 },
				itemsPadding: 2,
				arrows: { sm: false, md: true },
				itemWrapperClass: 'py-1',
			}"
			:flicking-options="{
				align: 'prev',
				bound: true,
				moveType: { xs: 'freeScroll', md: 'snap' },
				preventClickOnDrag: true,
			}"
		>
			<template #panel="{ item, i }">
				<Media
					v-if="item.customCard"
					:src="offerBg"
					height="100%"
					class="rounded"
					style="position: relative"
				>
					<div
						class="font-8 white--text text-center font-weight-black"
						style="position: absolute; bottom: 50%; width: 100%"
					>
						{{ item.title }}
					</div>
					<div class="text-center" v-if="item.ctaTxt && item.ctaLink">
						<SafeLink :to="item.ctaLink">
							<Button
								rounded
								elevation="0"
								large
								color="red"
								class="px-lg-13 py-lg-8"
								style="position: absolute; bottom: 2rem; left: 50%; transform: translateX(-50%)"
							>
								<span class="font-2 font-alt font-weight-bold">{{ item.ctaTxt }}</span>
							</Button>
						</SafeLink>
					</div>
				</Media>
				<ProductCard
					:product="item"
					v-else
					list-id="home-e-products-slider"
					:list-index="getProdListIdx(i)"
				/>
			</template>
		</Carousel>
	</Container>
</template>

<style scoped>
.carousel >>> .arrow {
	width: 55px;
	height: 55px;
}
.carousel >>> .arrow-icon {
	color: var(--v-purple-base) !important;
	font-size: 70px !important;
}
</style>
