<script>
export default {
	data() {
		return {
			images: this.$srv('Home_K_Cards', []),
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.name === 'xs'
		},
	},
}
</script>

<template>
	<Container class="pa-0" v-if="images">
		<v-row class="pa-0">
			<v-col cols="12" class="d-flex" :class="isMobile && 'flex-column flex-sm-row'">
				<div class="large-box-parent" :class="isMobile ? 'large-box-parent-sm' : 'pa-3 pt-0'">
					<Media :src="images.img" aspect-ratio="1" width="100%" img-class="rounded-lg" />
				</div>

				<div :class="!isMobile ? 'small-boxes-parent' : 'small-boxes-sm-parent'">
					<div class="d-flex">
						<div class="each-image-parent pa-0 pa-sm-2 pt-2 pt-sm-0 pr-1">
							<SafeLink :to="images.LinkFirstBox">
								<Media
									width="100%"
									aspect-ratio="1.15"
									:src="images.imgFirst"
									img-class="rounded-lg"
								/>
							</SafeLink>
						</div>
						<div class="each-image-parent pa-0 pa-sm-2 pt-2 pt-sm-0 pl-1">
							<SafeLink :to="images.LinkSecondBox">
								<Media
									width="100%"
									aspect-ratio="1.15"
									:src="images.imgSecond"
									img-class="rounded-lg"
								/>
							</SafeLink>
						</div>
					</div>
					<div class="d-flex">
						<div class="each-image-parent pa-0 pa-sm-2 pt-2 pr-1">
							<SafeLink :to="images.LinkThirdBox">
								<Media
									width="100%"
									aspect-ratio="1.15"
									:src="images.imgThird"
									img-class="rounded-lg"
								/>
							</SafeLink>
						</div>
						<div class="each-image-parent pa-0 pa-sm-2 pt-2 pl-1">
							<SafeLink :to="images.LinkFourthBox">
								<Media
									width="100%"
									aspect-ratio="1.15"
									:src="images.imgFourth"
									img-class="rounded-lg"
								/>
							</SafeLink>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped>
.large-box-parent {
	width: 46.8%;
}

.small-boxes-parent {
	width: 53.2%;
}

.small-boxes-sm-parent {
	width: 100%;
}

.each-image-parent {
	width: 50%;
}

.img-small {
	width: 50%;
}

.large-box-parent-sm {
	width: 100%;
}
</style>
