import { TermsCondition, OurBooks /*OurBooksAbout, InfoBooksCustomization*/ } from '@/site/autoloader'

export default [
	{
		path: '/terminos-y-condiciones',
		name: 'others.terms',
		component: TermsCondition,
	},
	{
		path: '/mira-nuestros-libros',
		name: 'others.our-books',
		component: OurBooks,
	},
	/*{
		path: '/de-que-se-tratan-nuestras-historias',
		name: 'others.our-books-about',
		component: OurBooksAbout,
	},
	{
		path: '/que-se-personaliza-en-cada-libro',
		name: 'others.info-books-customization',
		component: InfoBooksCustomization,
	},*/
]
