<script>
export default {
	name: 'ProductsCarousel',
	props: {
		products: Array,
		listId: String,
	},
	mounted() {
		if (this.products.length && this.listId) {
			this.$eventer.trigger('product-list:view', {
				products: this.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Container :fluid="{ xs: true, md: false }">
		<Carousel
			class="product-card-carousel"
			:items="products"
			:options="{
				itemsPadding: 2,
				panelsPerView: { xs: 1.5, sm: 3.5, md: 4 },
				arrows: { md: true },
				arrowsMargin: 30,
				itemWrapperClass: 'py-1',
			}"
			:flicking-options="{
				align: 'prev',
				bound: true,
			}"
		>
			<template #panel="{ item, i }">
				<ProductCard :product="item" :list-id="listId" :list-index="i" />
			</template>
		</Carousel>
	</Container>
</template>
