<script>
export default {
	lang: 'home',
	computed: {
		facebook() {
			return this.$srv('BusinessInfo.facebook')
		},
		instagram() {
			return this.$srv('BusinessInfo.instagram')
		},
	},
}
</script>

<template>
	<Container
		v-if="facebook || instagram"
		class="d-flex flex-column flex-sm-row justify-center align-center px-5 px-md-0"
	>
		<div class="font-3 primary--text font-weight-bold font-alt">
			{{ 'Seguinos en nuestras redes' | lang }}
		</div>
		<div class="mt-3 mt-md-0">
			<SafeLink v-if="facebook" :to="facebook">
				<Button fab dark small elevation="0" color="primary" class="ml-2">
					<v-icon class="mr-0">mdi-facebook</v-icon>
				</Button>
			</SafeLink>
			<SafeLink v-if="instagram" :to="instagram">
				<Button fab dark small elevation="0" color="primary" class="ml-2">
					<v-icon class="mr-0">mdi-instagram</v-icon>
				</Button>
			</SafeLink>
		</div>
	</Container>
</template>
