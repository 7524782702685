<script>
export default {
	lang: 'home',
	data() {
		return {
			carouselItems: this.$srv('Home_D_Slider', []),
		}
	},
}
</script>

<template>
	<div class="purple py-10" v-if="carouselItems.length">
		<div class="text-center font-6 font-weight-bold grey--text text--lighten-3 font-alt pb-3">
			{{ '¿Cómo personalizo mi cuento?' | lang }}
		</div>
		<Container>
			<Carousel
				class="carousel"
				:items="carouselItems"
				:options="{ arrows: { md: true }, arrowsMargin: -20 }"
			>
				<template #panel="{ item }">
					<SafeLink :to="item.link" class="px-3">
						<Media :src="item.img" contain :aspect-ratio="4.13" img-class="rounded-xl" />
					</SafeLink>
				</template>
				<template #free-slot="{ currentIndex, moveTo }">
					<div class="index-container">
						<div
							class="index"
							:class="{ active: currentIndex == i - 1 }"
							v-for="i of carouselItems.length"
							:key="i"
							@click="moveTo(i - 1)"
						></div>
					</div>
				</template>
			</Carousel>
		</Container>
	</div>
</template>

<style scoped>
.index-container {
	position: absolute;
	bottom: -1.6rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 4;
	display: flex;
}

.index {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	transition: all 0.3s;
	cursor: pointer;
	background-color: #bdb9d3;
}

.index.active {
	transform: scale(1.6);
	background-color: #ffffff;
}
.carousel >>> .arrow {
	width: 55px;
	height: 55px;
}
.carousel >>> .arrow-icon {
	color: white !important;
	font-size: 70px !important;
}
</style>
