<script>
export default {
	name: 'ProductCard',
	props: {
		product: Object,
		listId: String,
		listIndex: Number,
	},
	computed: {
		productRoute() {
			return {
				name: 'product',
				params: { urlName: this.product.urlName, id: this.product.id },
			}
		},
		mainVariant() {
			return this.product.variants.find((variant) => variant.main) || this.product.variants[0]
		},
		prevPrice() {
			return this.mainVariant.pvPrice.prevPrice
		},
		price() {
			return this.mainVariant.pvPrice.price
		},
		discountPct() {
			return this.mainVariant.pvPrice.discountPct
		},
		mainImage() {
			return this.product.images[0].square
		},
		secondImage() {
			return this.product.images[1]?.square
		},
		currency() {
			return this.mainVariant.pvPrice.priceConfig.currency.sign
		},
	},
	methods: {
		async cardClick() {
			this.$eventer.trigger('product:click', {
				product: this.product,
				variant: this.mainVariant,
				listId: this.listId,
				index: this.listIndex,
			})
		},
	},
}
</script>

<template>
	<v-card
		hover
		flat
		tile
		@click="cardClick"
		:to="productRoute"
		class="elevation-1"
		height="100%"
		style="overflow: hidden"
	>
		<div class="d-flex flex-column justify-space-between" style="height: 100%">
			<slot name="images" v-bind="{ mainImage, secondImage }">
				<ProductCardImage :mainImage="mainImage" :secondImage="secondImage" />
			</slot>
			<div class="body-card">
				<div class="card-content d-flex flex-column py-3 px-4">
					<div class="line-clamp-2 font-1 purple--text font-weight-bold">
						{{ product.name }}
					</div>
					<v-spacer />
					<PriceLayout
						:prev-price="prevPrice"
						:price="price"
						:discount-pct="discountPct"
						:currency="currency"
					/>
					<div class="flex-grow-0 mt-3">
						<Button color="purple" block small>
							<v-icon small class="mr-1">mdi-cart-outline</v-icon> COMPRAR
						</Button>
					</div>
				</div>
				<div class="bubble" style="left: -30%"></div>
				<div class="bubble" style="left: 50%; transform: translateX(-50%)"></div>
				<div class="bubble" style="right: -30%"></div>
			</div>
		</div>
		<div class="wishlist-position">
			<ProductWishlistToggler :productId="product.id" color="transparent" />
		</div>
	</v-card>
</template>

<style scoped>
.card-content {
	position: relative;
	z-index: 1;
	min-height: 150px;
}

.body-card {
	height: 100%;
	background-color: #eff7fa;
	position: relative;
}

.wishlist-position {
	position: absolute;
	top: 5px;
	right: 5px;
}

.bubble {
	position: absolute;
	background-image: linear-gradient(to bottom, #e0f0f5 5%, #eff7fa 95%);
	width: 60%;
	height: 60%;
	min-height: 8rem;
	border-radius: 50%;
	top: -2rem;
}
</style>
