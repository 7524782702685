<script>
export default {
	name: 'Home',
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<div>
		<Home-A-Slider class="mb-10" />
		<Home-B-ProductsCards />
		<Home-C-Cards />
		<Home-D-Slider />
		<Home-E-SliderCards />
		<Home-F-Cards />
		<Home-G-Video class="mb-10" />
		<Home-H-Slider />
		<Home-I-NewsLetter />
		<Home-J-SocialInfo class="my-10" />
		<Home-K-Cards />
		<Home-L-Tabs />
	</div>
</template>
