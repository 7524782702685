<script>
import { get, sync } from 'vuex-pathify'

export default {
	name: 'MobileMenuDrawer',
	lang: 'layout',
	computed: {
		menuMobileDrawer: sync('shop/menuMobileDrawer'),
		user: get('shop/user'),
	},
	methods: {
		showLoginDrawer() {
			this.menuMobileDrawer = false
			this.$nextTick(() => {
				this.$store.set('shop/loginDrawer', true)
			})
		},
	},
}
</script>

<template>
	<v-navigation-drawer
		class="drawer-index pa-0"
		v-model="menuMobileDrawer"
		width="100%"
		max-width="600"
		overlay-color="#000"
		temporary
		:overlay-opacity="0.8"
		fixed
		touchless
	>
		<v-row class="justify-space-between align-center ma-8">
			<router-link :to="{ name: 'home' }">
				<img
					:alt="$srv('BusinessInfo.name')"
					class="shrink mr-sm-2 mr-10"
					:src="$srv('SiteLogo.logo')"
					width="200"
					style="width: auto; height: 60px"
				/>
			</router-link>
			<Button :ripple="false" plain text @click="menuMobileDrawer = false">
				<v-icon x-large color="purple">mdi-close</v-icon>
			</Button>
		</v-row>

		<v-list-item-group class="px-8">
			<v-list-item
				v-for="(linkData, i) of $srv('SiteNavbarLinks')"
				:key="i"
				:to="linkData.link"
				@click="menuMobileDrawer = false"
				class="font-3 font-weight-bold py-3 px-0 purple--text"
			>
				{{ linkData.text }}
			</v-list-item>
			<v-list-item
				v-if="user"
				:to="{ name: 'user.account' }"
				@click="menuMobileDrawer = false"
				class="font-3 font-weight-bold py-3 px-0 purple--text"
			>
				<span>{{ 'Mi cuenta' | lang }}</span>
			</v-list-item>
			<v-list-item
				v-else
				@click.stop="showLoginDrawer"
				class="font-3 font-weight-bold py-3 px-0 purple--text"
			>
				<span>{{ 'Mi cuenta' | lang }}</span>
			</v-list-item>
			<div class="mt-10 px-0">
				<Button fab dark color="purple elevation-0" class="mr-6">
					<v-icon color="yellow lighten-1">mdi-facebook</v-icon>
				</Button>
				<Button fab dark color="purple" class="mr-6 elevation-0">
					<v-icon color="yellow lighten-1">mdi-instagram</v-icon>
				</Button>
				<Button fab dark color="purple" class="elevation-0">
					<v-icon color="yellow lighten-1">mdi-email</v-icon>
				</Button>
			</div>
		</v-list-item-group>
	</v-navigation-drawer>
</template>
