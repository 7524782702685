<script>
export default {
	data() {
		return {
			items: this.$srv('Home_C_Cards', []),
		}
	},
}
</script>

<template>
	<Container class="pa-0 pt-16 pb-16" v-if="items">
		<v-row>
			<v-col cols="12" v-if="items.titleText" class="d-flex justify-center mb-8">
				<div class="top-text text-center purple--text font-4 font-md-6 font-weight-bold font-alt">
					{{ items.titleText }}
				</div>
			</v-col>
			<v-col cols="12" sm="4" md="4" v-if="items.imgFirst">
				<ImageCard
					:image="items.imgFirst"
					:cta-txt="items.ctaTextFirstBox"
					:cta-link="items.LinkFirstBox"
					:cta-color="items.ctaColorFirstBox"
					:cta-title="items.firstCardText"
					:bg-color="items.bgColorFirstBox"
					:text-color="items.firstCardTextColor"
				/>
			</v-col>
			<v-col cols="12" sm="4" md="4" v-if="items.imgSecond">
				<ImageCard
					:image="items.imgSecond"
					:cta-txt="items.ctaTextSecondBox"
					:cta-link="items.LinkSecondBox"
					:cta-color="items.ctaColorSecondBox"
					:cta-title="items.secondCardText"
					:bg-color="items.bgColorSecondBox"
					:text-color="items.secondCardTextColor"
				/>
			</v-col>
			<v-col cols="12" sm="4" md="4" v-if="items.imgThird">
				<ImageCard
					:image="items.imgThird"
					:cta-txt="items.ctaTextThirdBox"
					:cta-link="items.LinkThirdBox"
					:cta-color="items.ctaColorThirdBox"
					:cta-title="items.thirdCardText"
					:bg-color="items.bgColorThirdBox"
					:text-color="items.thirdCardTextColor"
				/>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped>
.top-text {
	max-width: 600px;
	width: 100%;
}
</style>
