<script>
import { get } from 'vuex-pathify'

export default {
	name: 'AppBar',
	lang: 'layout',
	props: {
		showMenu: Boolean,
	},
	data() {
		return {
			searchText: null,
			top: this.$srv('Nv_Top'),
		}
	},
	computed: {
		user: get('shop/user'),
		routeSearch: get('route@query.search'),
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
		order: get('cart/order'),
		orderItemsQty() {
			return this.order?.items.reduce((sum, item) => sum + item.qty, 0)
		},
	},
	watch: {
		routeSearch(value) {
			this.searchText = value
		},
	},
	methods: {
		showLoginDrawer() {
			this.$store.set('shop/loginDrawer', true)
		},
		showCartDrawer() {
			this.$store.set('shop/cartDrawer', true)
		},
		showMenuMobileDrawer() {
			this.$store.set('shop/menuMobileDrawer', true)
		},
		search() {
			this.$shop.search(this.searchText)
		},
	},
}
</script>

<template>
	<header>
		<div v-if="top" :class="top.bgColor" class="py-2 lighten-1">
			<Container
				class="d-flex justify-space-between align-center font-alt px-0 px-lg-5 py-0"
				style="height: 100%"
			>
				<div :style="{ color: top.textColor }" class="font-weight-bold">{{ top.text }}</div>
				<SafeLink :to="top.ctaLink" v-if="top.ctaLink && top.ctaText">
					<Button
						:color="top.ctaBgColor || 'yellow'"
						:style="{ color: `${top.ctaTextColor || '#FFF'} !important` }"
					>
						{{ top.ctaText }}
					</Button>
				</SafeLink>
			</Container>
		</div>
		<Container class="d-flex py-2 align-center" max-width="95%" :fluid="{ xs: true, md: false }">
			<Button @click.stop="showMenuMobileDrawer" icon plain class="d-md-none">
				<v-icon color="purple"> mdi-menu </v-icon>
			</Button>

			<SafeLink :to="{ name: 'home' }" class="mx-3">
				<Media :alt="$srv('BusinessInfo.name')" :src="$srv('SiteLogo.logo')" width="3rem" eager />
			</SafeLink>
			<SafeLink
				v-for="(linkData, i) of $srv('SiteNavbarLinks')"
				:key="i"
				:to="linkData.link"
				custom
				v-slot="{ attrs }"
				class="d-none d-md-block"
			>
				<Button v-bind="attrs" large color="purple" text rounded class="font-alt text-capitalize px-3">
					<span class="font-1">{{ linkData.text }}</span>
				</Button>
			</SafeLink>

			<v-form @submit.prevent="search" class="flex-grow-1 mx-5">
				<v-text-field
					class="rounded-pill"
					type="search"
					autocomplete="off"
					placeholder=""
					append-icon="mdi-magnify primary--text"
					outlined
					flat
					hide-details
					v-model="searchText"
					@click:append="search"
					dense
				/>
			</v-form>

			<Button
				v-if="user"
				text
				class="font-alt px-3"
				color="purple"
				:icon="$vuetify.breakpoint.xs"
				:to="{ name: 'user.account' }"
			>
				<span v-if="!isMobile" class="text-capitalize">
					{{ user.firstname }}
				</span>
				<v-icon> mdi-account-outline </v-icon>
			</Button>

			<Button
				v-else
				text
				@click.stop="showLoginDrawer"
				class="font-alt px-3"
				color="purple"
				:icon="$vuetify.breakpoint.xs"
			>
				<span v-if="!isMobile" class="text-capitalize"> {{ 'Mi cuenta' | lang }} </span>
				<v-icon class="d-md-none pa-0"> mdi-account-outline </v-icon>
			</Button>

			<Button
				text
				@click.stop="showCartDrawer"
				class="purple--text font-alt d-flex mr-3"
				:icon="$vuetify.breakpoint.xs"
			>
				<span v-if="!isMobile" class="text-capitalize"> {{ 'Carrito' | lang }} </span>
				<v-badge :content="orderItemsQty" :value="!!orderItemsQty" color="error" overlap>
					<v-icon color="purple">mdi-cart-outline</v-icon>
				</v-badge>
			</Button>
		</Container>
	</header>
</template>

<style lang="scss" scoped>
.v-btn.v-btn--active {
	color: #fff !important;
	background-color: #ca6064;
	* {
		color: #fff !important;
	}
}

.top {
	width: 100%;
	height: 3rem;
}
</style>
