var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('Container',{staticClass:"py-15",attrs:{"fluid":{ xs: true, sm: false }}},[_c('Carousel',{staticClass:"carousel",attrs:{"items":_vm.items,"options":{
			panelsPerView: { xs: 1.5, sm: 3, md: 4 },
			itemsPadding: 2,
			arrows: { sm: false, md: true },
			itemWrapperClass: 'py-1',
		},"flicking-options":{
			align: 'prev',
			bound: true,
			moveType: { xs: 'freeScroll', md: 'snap' },
			preventClickOnDrag: true,
		}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
		var item = ref.item;
		var i = ref.i;
return [(item.customCard)?_c('Media',{staticClass:"rounded",staticStyle:{"position":"relative"},attrs:{"src":_vm.offerBg,"height":"100%"}},[_c('div',{staticClass:"font-8 white--text text-center font-weight-black",staticStyle:{"position":"absolute","bottom":"50%","width":"100%"}},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.ctaTxt && item.ctaLink)?_c('div',{staticClass:"text-center"},[_c('SafeLink',{attrs:{"to":item.ctaLink}},[_c('Button',{staticClass:"px-lg-13 py-lg-8",staticStyle:{"position":"absolute","bottom":"2rem","left":"50%","transform":"translateX(-50%)"},attrs:{"rounded":"","elevation":"0","large":"","color":"red"}},[_c('span',{staticClass:"font-2 font-alt font-weight-bold"},[_vm._v(_vm._s(item.ctaTxt))])])],1)],1):_vm._e()]):_c('ProductCard',{attrs:{"product":item,"list-id":"home-e-products-slider","list-index":_vm.getProdListIdx(i)}})]}}],null,false,713338769)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }