<script>
export default {
	data() {
		return {
			items: this.$srv('Home_F_Cards', []),
		}
	},
}
</script>

<template>
	<Container class="py-15" v-if="items">
		<v-row>
			<v-col cols="12" v-if="items.titleText">
				<div class="text-center primary--text font-6 font-weight-bold pb-8 font-alt">
					{{ items.titleText }}
				</div>
			</v-col>
			<v-col cols="12" sm="6" md="3" v-if="items.imgFirst">
				<ImageCard
					:image="items.imgFirst"
					:cta-txt="items.ctaTextFirstBox"
					:cta-link="items.LinkFirstBox"
				/>
			</v-col>
			<v-col cols="12" sm="6" md="3" v-if="items.imgSecond">
				<ImageCard
					:image="items.imgSecond"
					:ratio="0.8"
					:cta-txt="items.ctaTextSecondBox"
					:cta-link="items.LinkSecondBox"
					class="rounded"
				/>
			</v-col>
			<v-col cols="12" sm="6" md="3" v-if="items.imgThird">
				<ImageCard
					:image="items.imgThird"
					:ratio="0.8"
					:cta-txt="items.ctaThirdBoxText"
					:cta-link="items.LinkThirdBox"
					class="rounded"
				/>
			</v-col>
			<v-col cols="12" sm="6" md="3" v-if="items.imgFourth">
				<ImageCard
					:image="items.imgFourth"
					:ratio="0.8"
					:cta-txt="items.ctaTextFourthBox"
					:cta-link="items.LinkFourthBox"
					class="rounded"
				/>
			</v-col>
		</v-row>
	</Container>
</template>
